import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import network from "../../constants/Network";
import GlobalContext from "../context/GlobalContext";
import axios from "axios";
import { Modal } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from 'date-fns';

const UserSkill = ({ location }) => {

  const params = new URLSearchParams(location.search);
  const isMountedRef = useRef(false);

  const gContext = useContext(GlobalContext);
  const userId = gContext?.user?.id || '';
  const [userSkills, setUserSkills] = useState([]);
  const [skills, setSkills] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState('');
  const [isFocused, setIsFocused] = useState('');
  const [errors, setErrors] = useState({});
  const [modalButtonGroup, setModalButtonGroup] = useState('createSkill');

  useEffect(() => {
    if (!userId) {
      console.log('Waiting for the user id');
      return;
    }

    isMountedRef.current = true;

    fetchUserSkills();
    fetchSkills();

    return () => {
      isMountedRef.current = false;
    };
  }, [userId]);

  const handleSkillCreate = () => {
    setModalVisible(true);
    setModalButtonGroup('createSkill');
    setFormData({
      id: '',
      skill: '',
    });
    setIsFocused('');
  }

  const handleSkillEdit = (data) => {
    setModalButtonGroup('editSkill');
    setModalVisible(true);
    setFormData({
      id: data?.id || '',
      skill: data?.skill?.title || '',
    });
    setIsFocused('skillText');
  }

  const handleClose = () => {
    setModalVisible(false);
    setFormData({
      id: '',
      skill: '',
    });
    setErrors({});
    setIsFocused('');
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSuggestionClick = (e, field, value) => {
    e.preventDefault();
    setFormData({ ...formData, [field]: value });
    setShowSuggestions('');
    setFilteredSuggestions([]);
    setIsFocused('skillText');
  };

  const [formData, setFormData] = useState({
    id: '',
    skill: '',
  });

  const imageBaseUrl = `${network.serverip}/images/`;

  const handleWordChange = (word) => {
    return word
      .replace(/_/g, ' ')
      .toUpperCase();
  };

  const fetchUserSkills = async () => {

    if (!userId) {
      gContext.toggleSignInModal();
      return
    }

    try {
      const response = await axios.get(`${network.serverip}/api/user-skills-list-web/${userId}`);

      if (response.data.success && isMountedRef.current) {

        const userSkillData = response.data.data || [];

        setUserSkills(userSkillData);
        console.log('This is the fetched user skills:', userSkillData);

      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setUserSkills([]);
        }
        console.error('Failed to fetch user skills');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setUserSkills([]);
      }
      console.error('Error fetching user skills:', error);
    }
  };

  const fetchSkills = async () => {
    try {
      const response = await axios.get('/skills-list-web', {
        baseURL: `${network.serverip}/api`,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success && isMountedRef.current) {
        const skillsData = (response.data.data || []);
        const titles = skillsData.map(item => item.title);
        setSkills(titles);
        console.log('Fetched skills:', titles);
      } else if (!response.data.success) {
        console.error('Failed to fetch skills');
      }

    } catch (error) {
      console.error('Error fetching skills:', error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const uploadResult = await handleSubmit();
      if (uploadResult.success) {
        setModalVisible(false);
        setFormData({
          id: '',
          skill: '',
        });
        setErrors({});
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSaveAndAddAnother = async (e) => {
    e.preventDefault();
    try {
      const uploadResult = await handleSubmit();
      if (uploadResult.success) {
        setFormData({
          id: '',
          skill: '',
        });
        setErrors({});
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async () => {

    console.log('This is the formData', formData);

    setErrors({});

    if (!userId) {
      gContext.toggleSignInModal();
      return
    }

    let isValid = true;

    if (!formData.skill) {
      setErrors(prevErrors => ({
        ...prevErrors,
        skill: 'Please select a skill'
      }));
      isValid = false;
    }

    if (!isValid) {
      return { success: false };
    }

    try {

      formData.userId = userId;

      const response = await axios.post(
        `${network.serverip}/api/user-skills-${formData.id ? 'edit' : 'create'}-web`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.success && isMountedRef.current) {
        console.log(`User skill uploaded successfully:`, response.data);
        fetchUserSkills();
        fetchSkills();
        return { success: true };
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));
        return { success: false };
      }

    } catch (error) {
      console.error('Error uploading user skill:', error);

      if (error.response) {

        if (error.response.data.error) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...error.response.data.error,
          }));
        }

        if (error.response.data.message) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            uploadError: error.response.data.message,
          }));
        }

        return { success: false };

      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));

        return { success: false };

      }
    }
  }

  const handleSkillDelete = async (data) => {

    console.log('handle skill delete clicked');

    try {

      const response = await axios.delete(`${network.serverip}/api/user-skills-delete-web/${data.id}`);

      if (response.data.success && isMountedRef.current) {
        console.log('User skill deleted successfully:', response.data);
        fetchUserSkills();
        fetchSkills();
        setFormData({
          id: '',
          skill: '',
        });
        setModalVisible(false);
        setErrors({});
      } else {
        console.error('Failed to delete user skill:', response.data);
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));
      }
    } catch (error) {
      console.error('Failed to delete user skill:');
      setErrors(prevErrors => ({
        ...prevErrors,
        uploadError: 'Something went wrong, please try again',
      }));
    }

  }


  const styles = UserSkillStyling();

  return (
    <>
      <PageWrapper headerConfig={{ button: "profile" }}>
        <div className="bg-default-2 pt-32 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            {/* <!-- back Button --> */}
            <div className="row justify-content-center">
              <div className="col-10 dark-mode-texts">
                <div className="mb-9">
                  <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate('/');
                    }
                  }} className="d-flex align-items-center ml-4">
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">

              <div className="col-10">
                <div className="bg-white rounded-4 shadow-9">

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <button
                      onClick={() => { }}
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Skill
                      </h4>
                    </button>

                    <div>
                      {userSkills && userSkills.length > 0 ? (
                        userSkills.map((skill, index) => {
                          return (
                            <div className="w-100 border-top" key={index}>
                              <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap flex-sm-nowrap">
                                <div className="d-flex align-items-start mt-0" style={{ width: '3rem' }}>
                                  <i className="fas fa-wrench font-size-4 text-success"></i>
                                </div>
                                <div className="d-flex justify-content-between w-100">
                                  <div className="w-100">
                                    <p className="mb-0 font-size-4 text-black-2 font-weight-semibold">
                                      {skill?.skill?.title || 'N/A'}
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-start">
                                    <button
                                      style={styles.editButton}
                                      onClick={() => { handleSkillEdit(skill) }}
                                    >
                                      <i className="fas fa-pencil-alt text-success font-size-4"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p>No skills available.</p>
                      )}
                    </div>
                    <div className="w-100 pt-9 mb-9 border-top pr-11">
                      <button
                        className="w-100 d-flex align-items-center justify-content-between"
                        onClick={() => { handleSkillCreate() }}
                        style={styles.addButton}
                      >
                        <p className="font-size-4 text-dodger font-weight-semibold mb-0">
                          {userSkills.length > 0 ? 'Add another skill' : 'Add skill'}
                        </p>
                        <i className="fas fa-plus text-success font-size-4"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
      <Modal
        size="lg"
        centered
        show={modalVisible}
      >
        <Modal.Body className="p-0">
          <button
            type="button"
            className="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10"
            onClick={handleClose}
          >
            <i className="fas fa-times"></i>
          </button>
          <form onSubmit={handleSave} className="bg-white rounded-8 overflow-hidden">
            <div className="p-10 w-100">
              <div className="d-flex justify-content-center border-bottom border-mercury pb-2">
                <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                  {modalButtonGroup === 'createSkill'
                    ? 'Add Skill'
                    : 'Edit Skill'}
                </h4>
              </div>
              <div className="w-100 border-bottom border-mercury mb-5">

                {isFocused === 'skillText' ? (
                  <div className="form-group mt-9 w-100">
                    <label
                      htmlFor="skill"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Skill:
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      placeholder="Enter skill"
                      id="skill"
                      name="skill"
                      value={formData.skill}
                      onChange={(e) => {
                        handleChange(e);
                        const text = e.target.value;
                        const filtered = skills.filter((skill) =>
                          skill.toLowerCase().includes(text.toLowerCase())
                        );
                        setFilteredSuggestions(filtered);
                        setShowSuggestions(text.length > 0 ? 'skill' : '');
                      }}
                      onFocus={() => setIsFocused('skillText')}
                      // onBlur={() => setIsFocused('')}
                      onBlur={(e) => {
                        if (!e.relatedTarget || !e.relatedTarget.classList.contains("suggestion-item")) {
                          setIsFocused('');
                          setShowSuggestions('');
                        }
                      }}
                      style={isFocused === 'skillText' ? styles.focussedInput : {}}
                    />
                    {showSuggestions === 'skill' && filteredSuggestions.length > 0 && (
                      <div
                        className="suggestions bg-white border border-gray"
                        style={styles.suggestionContainer}
                      >
                        {filteredSuggestions.map((suggestion, index) => (
                          <button
                            key={index}
                            className="suggestion-item font-size-4 text-black-2 mt-0 p-2 border-bottom w-100 text-left"
                            style={styles.suggestionItemStyle}
                            onClick={(e) => handleSuggestionClick(e, 'skill', suggestion)}
                          >
                            {suggestion}
                          </button>
                        ))}
                      </div>
                    )}
                    {errors?.skill ? <p className="font-size-3 text-danger">
                      {errors?.skill}
                    </p> : null}
                  </div>

                ) : (

                  <div className="form-group">
                    <label
                      htmlFor="skill"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Skill::
                    </label>
                    <select
                      id="skill"
                      name="skill"
                      className="form-control"
                      value={formData.skill}

                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === 'other') {
                          setIsFocused('skillText');
                          setFormData({ ...formData, skill: '' });
                        } else {
                          handleChange(e);
                        }
                      }}

                      onFocus={() => setIsFocused('skill')}
                      onBlur={() => setIsFocused('')}
                      style={isFocused === 'skill' ? styles.focussedInput : {}}
                    >
                      <option value="">Select skill</option>
                      {skills.map((skill, index) => (
                        <option key={index} value={skill}>
                          {skill}
                        </option>
                      ))}
                      <option value="other">Other</option>
                    </select>
                    {errors?.skill ? <p className="font-size-3 text-danger">
                      {errors.skill}
                    </p> : null}
                  </div>
                )}

              </div>

              <div className="w-100 d-flex justify-content-end">
                {modalButtonGroup === 'createSkill' ? (
                  <div className="w-100 d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5">
                      Cancel
                    </button>
                    <button
                      onClick={handleSaveAndAddAnother}
                      type="button"
                      className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5 mx-5">
                      Save and add another
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success h-px-48 rounded-3 mb-5 px-5">
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="w-100 d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5">
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() => { handleSkillDelete(formData) }}
                      className="btn btn-outline-danger h-px-48 rounded-3 mb-5 px-5 mx-5">
                      Delete
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success h-px-48 rounded-3 mb-5 px-5">
                      Save
                    </button>
                  </div>
                )}
              </div>

              <div className="w-100 d-flex justify-content-center">
                {errors?.uploadError ? <p className="font-size-3 text-danger">
                  {errors?.uploadError}
                </p> : null}
              </div>

            </div>
          </form>
        </Modal.Body>
      </Modal >
    </>
  );
};
export default UserSkill;

function UserSkillStyling() {
  const styles = {
    collapsibleHeadingButton:
    {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    downloadButton: {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },
    addButton: {
      border: 'none',
      outline: 'none',
      background: 'transparent',
    },
    editButton: {
      border: 'none',
      outline: 'none',
      background: 'transparent',
    },
    suggestionContainer: {
      maxHeight: "200px",
      overflowY: "auto",
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      borderBottomLeftRadius: "5px",
      borderBottomRightRadius: "5px",
    },
    suggestionItemStyle: {
      cursor: "pointer",
      backgroundColor: "white",
      border: "none",
      outline: 'none',
    },
    focussedInput: {
      borderColor: '#3498db',
      color: '#333',
      outline: 'none',
      borderWidth: '2px',
      boxShadow: '0 0 5px rgba(52, 152, 219, 0.5)',
    }
  };

  return styles;
}

